<template>
  <div class="popup" v-if="show">
      <div class="bg"></div>
      <div class="wrap">
          <div class="upper">
              <textarea name="content"  cols="30" @input='getnum($event.target.value)' rows="10" placeholder="请输入您的自我介绍（100字以内）" v-model="text"></textarea>
              <span>{{num}}</span>
          </div>
          <div class="btns">
              <div class="cancle" @click="cancle">
                  取消
              </div>
              <div class="determine" @click="determine">
                  保存
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
           text:"", num:100,
        }
    },
    methods:{
        getnum(e){
             this.num=100-this.text.length
             this.$emit("input", e);
        },
    },
    props:['show','cancle','determine',]
}
</script>

<style lang='less'>
    .popup{
        width: 100%;
        height:100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        .bg{
            width: 100%;
            height:100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color:rgba(0,0,0,.3);
        }
        .wrap{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin:-2rem auto;
            width: 6.7rem;
            height: 4.08rem;
            background-color:#fff;
            border-radius:.2rem;
            .upper{
                width: 100%;
                height: 3.2rem;
                padding:.42rem .44rem;
                box-sizing: border-box;
                textarea{
                    width: 100%;
                    border:.01rem solid rgba(218,218,218,0.3);
                    height: 2.3rem;
                    font-size:.28rem;
                    padding: .24rem;
                    box-sizing: border-box;
                }
                span{
                    position: absolute;
                    right: .6rem;
                    bottom: 1.44rem;
                    color:#BAB9B9;
                    font-size:.24rem;
                }
            }
            .btns{
                width: 100%;
                height: .86rem;
                border-top:.01rem solid rgba(218,218,218,0.3);
                line-height: .86rem;
                text-align: center;
                font-size:.28rem;
                display: flex;
                .cancle{
                    width: 50%;
                    color:#9B9B9B;
                    border-right:.01rem solid rgba(218,218,218,0.3);
                }
                .determine{
                    width: 50%;
                    color:#FF865C;
                }
            }
        }
    }
</style>