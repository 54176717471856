<template>
  <div class="my">
    <div class="avatarWrap">
      <div class="avatar">
        <van-image
          round
          fit="cover"
          width="2.4rem"
          height="2.4rem"
          :src="userinfo.user_avatar ? userinfo.user_avatar : defaultImg"
        />
        <img :src="modifyIcon" alt="" class="iconimg" />
        <input type="file" name="avatar" @change="modifyImg" />
      </div>
    </div>
    <div class="myinfo">
      <p class="name">
        {{ userinfo.nick_name }}
      </p>
      <p class="autograph">
        {{ userinfo.introduce }}
      </p>
    </div>
    <div class="list">
      <div class="line" @click="tourl">
        <div class="l">
          <img :src="serviceIcon" alt="" /><span> 申请服务费</span>
        </div>
        <img :src="rightIcon" alt="" class="rightIcon" />
      </div>
      <ul>
        <li v-for="(v, i) in list" :key="i" @click="modify(i)">                                             
          <div class="item" v-if="i !== 2">
            <div class="l">
              <img :src="v.icon" alt="" /><span> {{ v.name }}</span>
            </div>
            <div class="r">
              <span :class="v.nickname ? 'fontstyle' : ''">{{
                v.nickname ? v.nickname : "去修改"
              }}</span>
              <img
                :src="rightIcon"
                alt=""
                class="rightIcon"
                :class="v.nickname ? 'vis' : ''"
              />
            </div>
          </div>
          <div class="item" v-if="i == 2 && login_token">
            <div class="l">
              <img :src="v.icon" alt="" /><span> {{ v.name }}</span>
            </div>
            <div class="r">
              <span :class="v.nickname ? 'fontstyle' : ''">{{
                v.nickname ? v.nickname : "去修改"
              }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <pop
      :show="popshow"
      :getnum="getnum"
      :cancle="cancle"
      :determine="determine"
      v-model="text"
    ></pop>
    <van-dialog
      v-model="nickNameShow"
      title="修改昵称"
      show-cancel-button
      width="300"
      @confirm="confirm"
      @cancel="nickNameCancel"
    >
      <input type="text" v-model="nickName" placeholder="请输入昵称" />
    </van-dialog>
    <div class="btn" @click="quit" v-if="login_token">退出登录</div>
    <div style="height: 1.08rem;"></div>
    <tabbar :data="tabbarlist" :Jump="jump" :index="2"></tabbar>
  </div>
</template>

<script>
import pop from "@/components/popup/index";
import tabbar from "@/components/tabbar/tabbar";
import api from "@/api/api";
export default {
  data() {
    return {
      modifyIcon: require("@/img/my/icon_xiugaitouxiang.png"),
      serviceIcon: require("@/img/my/icon_fuwufei.png"),
      rightIcon: require("@/img/my/Arrow_right.png"),
      login_token: localStorage.getItem("login_token"),
      popshow: false,
      num: 100,
      text: "",
      nickName: "",
      nickNameShow: false,
      imgData: "",
      defaultImg: require("../../img/my/avatar.png"),
      userinfo: "",
      openid: "oftPL6r-CX6keK8gnFwADpmwiAls",
      list: [
        {
          icon: require("@/img/my/icon_nicheng.png"),
          name: "昵称",
        },
        {
          icon: require("@/img/my/icon_gerenjianjie.png"),
          name: "个人简介",
        },
        {
          icon: require("@/img/my/dangqianyonghu.png"),
          name: "当前用户",
          nickname: "请登录",
        },
      ],
      tabbarlist: [
        {
          name: "首页",
          img: require("@/img/tabbar/Icon_Home_default.png"),
        },
        {
          name: "客户",
          img: require("@/img/tabbar/Icon_Heart_default.png"),
        },
        {
          name: "我的",
          img: require("@/img/tabbar/Icon_Profile_selected.png"),
        },
      ],
    };
  },
  methods: {
    getnum() {
      console.log(1);
    },
    // 申请服务费
    tourl() {
      var _this = this;
      if (!this.login_token) {
        this.$router.push({
          path: "/login",
        });
      } else {
        //获取用户是否选择合作方式
        _this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        var myData = new URLSearchParams();
        myData.append("openid", _this.openid);
        myData.append("login_token", _this.login_token);
        _this
          .$http({
            method: "post",
            url: "users/api.finance/getUserCoMode",
            data: myData,
          })
          .then((res) => {
            _this.$toast.clear();
            if (res.data.code == 0) {
              console.log("未选择合作方式");
              _this.$router.push({
                path: "/realname/cooperation/mode",
              });
            } else if (res.data.code == 1) {
              console.log("已选择合作方式");
              this.$router.push({
                path: "/realname/service/service",
              });
            } else if (res.data.code == -100) {
              _this.$dialog
                .alert({
                  title: "标题",
                  message: "登录过期，请重新登录",
                })
                .then(() => {
                  _this.$router.push({
                    path: "/login",
                  });
                });
            } else if (res.data.code == -2) {
              _this.$toast(res.data.msg);
              localStorage.removeItem("login_token");
              localStorage.removeItem("nickName");
              this.login_token = "";
              this.list[2].nickname = "请登录";
            } else {
              _this.$toast(res.data.msg);
            }
          })
          .catch(() => {
            _this.$toast.clear();
            _this.$toast("网络请求失败");
          });
      }
    },
    cancle() {
      this.popshow = false;
    },
    determine() {
      this.popshow = false;
      api.details.modifyIntroduce(this.openid, this.text + "").then((res) => {
        if (res.data.code == 0) {
          console.log(res);
          this.userinfo.introduce = this.text;
          this.$set(this.userinfo, "introduce", this.text);
          this.$store.commit("keepWxUserInfo", this.userinfo);
          this.$toast("编辑成功");
        }
      });
    },
    confirm() {
      this.modifyNickName();
    },
    nickNameCancel() {
      console.log(0);
    },
    modify(i) {
      if (i == 0) {
        this.nickNameShow = true;
      } else if (i == 1) {
        this.popshow = true;
      }
    },
    jump(i) {
      if (i == 1) {
        this.$router.push("/customer");
      } else if (i == 0) {
        this.$router.push("/");
      }
    },
    modifyImg(e) {
      var avatar = e.target.files[0];
      let data = new FormData();
      data.append("file", avatar);
      this.imgData = data;
      api.my.uploadIMg(data).then((res) => {
        if (res.data.uploaded) {
          let url = res.data.url;
          api.my.modifyNickName(this.userinfo.id, this.openid, "", url)
            .then((res) => {
              if (res.data.code == 0) {
                this.userinfo.user_avatar = url;
                this.$store.commit("keepWxUserInfo", this.userinfo);
                this.$toast(res.data.msg);
              }
            });
        }
      });
    },
    modifyNickName() {
      api.my
        .modifyNickName(this.userinfo.id, this.openid, this.nickName, "")
        .then((res) => {
          if (res.data.code == 0) {
            this.userinfo.nick_name = this.nickName;
            this.list[2].nickname = this.userinfo.nick_name;
            this.$store.commit("keepWxUserInfo", this.userinfo);
            this.$toast(res.data.msg);
          }
        });
    },
    quit() {
      this.$toast("退出成功");
      localStorage.removeItem("login_token");
      localStorage.removeItem("nickName");
      this.login_token = "";
      this.list[2].nickname = "请登录";
    },
  },
  components: {
    pop,
    tabbar,
  },
  created() {
    this.openid = this.$store.state.openid;
    this.userinfo = this.$store.state.WxUserInfo;
    if (localStorage.getItem("nickName")) {
      this.list[2].nickname = localStorage.getItem("nickName");
    }
  },
};
</script>

<style lang="less">
.my {
  width: 100%;
  .avatarWrap {
    padding: 0.32rem 0;
    text-align: center;
    .avatar {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 auto;
      position: relative;
      border-radius: 50%;
      .iconimg {
        width: 0.84rem;
        height: 0.84rem;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
      }
    }
  }
  .myinfo {
    width: 100%;
    text-align: center;
    padding: 0 0.78rem 0 0.64rem;
    box-sizing: border-box;
    .name {
      margin-bottom: 0.34rem;
      font-weight: bold;
      font-size: 0.32rem;
    }
    .autograph {
      font-size: 0.28rem;
      margin-bottom: 0.42rem;
    }
  }
  .list {
    width: 100%;
    padding: 0 0.78rem 0 0.64rem;
    box-sizing: border-box;
    .line {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.48rem 0;
      box-sizing: border-box;
      justify-content: space-between;
      border-top: 0.02rem solid rgba(0, 0, 0, 0.05);
      border-bottom: 0.02rem solid rgba(0, 0, 0, 0.05);
      .l {
        img {
          width: 0.84rem;
          height: 0.84rem;
          vertical-align: middle;
          margin-right: 0.24rem;
        }
        span {
          font-size: 0.32rem;
        }
      }
      .rightIcon {
        width: 0.48rem;
      }
    }
    ul {
      li {
        .item {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0.48rem 0;
          box-sizing: border-box;
          justify-content: space-between;
          height: 1.32rem;
          .l {
            img {
              width: 0.84rem;
              height: 0.84rem;
              vertical-align: middle;
              margin-right: 0.24rem;
            }
            span {
              font-size: 0.32rem;
            }
          }
          .r {
            span {
              font-size: 0.24rem;
              color: #c0c0c0;
              vertical-align: middle;
            }
            .fontstyle {
              font-size: 0.28rem;
              padding-right: 0.3rem;
            }
            .rightIcon {
              width: 0.48rem;
              vertical-align: middle;
            }
            .vis {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
  .van-dialog__content {
    box-sizing: border-box;
    input {
      display: block;
      margin: 0.3rem auto;
      width: 4.32rem;
      padding: 0.2rem;
      box-sizing: border-box;
      border: 0.01rem solid #979797;
      color: #bab9b9;
      font-size: 0.26rem;
      border-radius: 0.1rem;
    }
  }
  .btn {
    width: 5.86rem;
    height: 0.88rem;
    margin: 0.6rem auto;
    background: rgba(241, 82, 35, 1);
    line-height: 0.88rem;
    text-align: center;
    color: #fff;
    border-radius: 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>